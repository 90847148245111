window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
	window.Popper = require('popper.js').default;
	window.$ = window.jQuery = require('jquery');
	window.Swal = require('sweetalert2');

  //jquery
  require("admin-lte/plugins/jquery/jquery.min.js");
  require("admin-lte/plugins/jquery-ui/jquery-ui.min.js");

	//Select2
  require("admin-lte/plugins/select2/js/select2.full.js");
  require("admin-lte/plugins/select2/js/select2.full.min.js");
  require("admin-lte/plugins/select2/js/select2.js");
  require("admin-lte/plugins/select2/js/select2.min.js");

	//SweetAlert
  require("admin-lte/plugins/sweetalert2/sweetalert2.all.min.js");

  //JQVMap
  require("admin-lte/plugins/jqvmap/jquery.vmap.min.js");
  require("admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js");

  //jQuery Knob Chart
  require("admin-lte/plugins/jquery-knob/jquery.knob.min.js");

  //bootstrap
  require("bootstrap");

  //Summernot
  require("admin-lte/plugins/summernote/summernote-bs4.min.js");

	//jszip
	window.JSZip = require("admin-lte/plugins/jszip/jszip.min.js");

  //datatable
  require("admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js");
  require("admin-lte/plugins/datatables/jquery.dataTables.min.js");
  require("admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js");
  require("admin-lte/plugins/datatables-responsive/js/dataTables.responsive.min.js");
  require("admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4.min.js");
  require("admin-lte/plugins/datatables-buttons/js/dataTables.buttons.min.js");
  require("admin-lte/plugins/datatables-buttons/js/buttons.html5.min.js");

  //adminLTe
  require("admin-lte");
  require("admin-lte/dist/js/demo.js");
  require("admin-lte/dist/js/pages/dashboard.js");
  //daterangepickers
  require("admin-lte/plugins/daterangepicker/daterangepicker.js");
  require("admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js");
  //overlayScrollbar
  require("admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js");
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
